export default {
  baseUrl: process.env.VUE_APP_API_URL,
  siteId: process.env.VUE_APP_SITE_ID,
  multiLanguage: true,
  useBrowserLanguage: false,
  defaultLanguage: 'en',
  // phoneSupportLine: '+90(850) 302 2293',
  // phoneSupportLine: '+1 469 595 8634',
  dark: false,
  flexChat: false,
  theme: {
    primary: '#f9002e',
    accent: '#666666'
  },
  questions: {
    enabled: false,
    visible: true,
    moderation: true
  },
  voting: {
    enabled: false
  }
};
